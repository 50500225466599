<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardBody>
          <CDataTable
            :items="iysLog"
            :fields="fields"
            :items-per-page="10"
            :active-page="1"
            :loading="loading"
            hover
            pagination
          >
            <template #createTime="{item}">
              <td>
                {{ item.createTime && dateFormat(item.createTime) }}
              </td>
            </template>
            <template #iysErrors="{item}">
                <td @mouseover="getTooltipMessage(item,'iysErrors')" v-c-tooltip.mouseenter="{content: iysErrorsMessage}">
                  {{ item.iysErrors }}
                </td>
              </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import moment from 'moment'

export default {
  name: 'IYSLog',
  data() {
    return {
      iysErrorsMessage: '',
      fields: [
        { key: 'createTime', label: 'Kayıt Tarihi', _style: 'font-size:12px' },
        { key: 'iysErrors', label: 'Hata Logu', _style: 'font-size:12px' },
        { key: 'iysMessage', label: 'Mesaj', _style: 'font-size:12px' },
        { key: 'iysRequestId', label: 'Request ID', _style: 'font-size:12px' },
        { key: 'iysStatus', label: 'Durum', _style: 'font-size:12px' },
        { key: 'requestInfo', label: 'Requst Bilgisi', _style: 'font-size:12px' }
      ],
    }
  },
  computed: {
    iysLog(){
      return this.$store.getters.iysLog
    },
    loading(){
      return this.$store.getters.notificationLoading
    }
  },
  methods: {
    getTooltipMessage(item,type) {
      if(type === 'iysErrors') {
        this.iysErrorsMessage = '';
        this.iysErrorsMessage = item.iysErrors;
      }
    },
    rowClicked(item) {
      this.lastItem = item
    },

    dateFormat(data){
      return moment(data).format('DD.MM.YYYY hh:mm:ss')
    },

    async iysLogList() {

      let params = {
        type: this.type
      }
      await this.$store.dispatch('iysLog_list', params)

    },
  },
  created() {
    this.iysLogList();
  }
}
</script>
